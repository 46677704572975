import metaDataJson from "@/data/meta.json";
import type { Category, Menu, Product, Schedule } from "@/types/types";
import moment from "moment";
import "moment-timezone";

interface MetaData {
  title: string;
  description: string;
}
const allowedLanguages = ["en", "fr", "es", "it", "ar", "de", "pt"];

export const getMetaData = (
  industry: string,
  city: string,
  menuName: string,
  pageType: string,
  category: string,
  language: string
): MetaData => {
  let title = "";
  let description = "";
  industry = industry?.replaceAll("-", "_").toLowerCase() || "";
  const validIndustries = [
    "restaurant",
    "cafe_bar",
    "hotel",
    "food_truck",
    "beauty_salon",
  ];
  if (!validIndustries.includes(industry)) {
    industry = "restaurant";
  }

  if (industry === "hotel") {
    industry = "restaurant";
  }

  try {
    // Access the specific section based on industry and pageType

    const pageData = (metaDataJson as any)[industry.toUpperCase()][
      `${pageType.toUpperCase()}_PAGE`
    ];
    if (!allowedLanguages.includes(language)) {
      language = "en";
    }

    title = pageData.META_TITLE[language]
      ?.replaceAll("[menu]", menuName)
      ?.replaceAll("[city]", city)
      ?.replaceAll("[category]", category);
    title = capitalizeText(title);
    description = pageData.META_DESCRIPTION[language]
      ?.replaceAll("[menu]", menuName)
      ?.replaceAll("[city]", city)
      ?.replaceAll("[category]", category);
    description = capitalizeText(description);
  } catch (error) {
    console.error("Error reading or processing the JSON file:", error);
  } finally {
    return { title, description };
  }
};

export const capitalizeText = (text: string): string => {
  if (!text) {
    return "";
  }
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

export const getImageUrl = (inputString: string) => {
  if (inputString?.includes(".qrmenu.com")) {
    return inputString;
  }
  inputString = inputString?.replaceAll("https://qrmenu.com", "");
  if (inputString?.startsWith("/menus")) {
    return process.env.NEXT_PUBLIC_QR_CDN + inputString.replace("/menus", "");
  }
  if (
    inputString?.includes("cdn.dopweb.com") &&
    !inputString?.includes("linear-gradient")
  ) {
    return inputString;
  }
  const regex = /url\("?([^")]+)"?\)/;
  const match = inputString.match(regex);
  if (match) {
    const url = match[1];
    if (url.includes("cdn.dopweb.com")) {
      return url;
    }
    return process.env.NEXT_PUBLIC_QR_CDN + url.replace("/menus", "");
  }

  return "";
};

export const isLanguageCode = (param: string): boolean => {
  return param.length === 2;
};

type ParsedParams = {
  siteName: string | undefined;
  categoryName: string | undefined;
  language: string | undefined;
  redirectNotFound: boolean;
};

export const parseLinkParams = (params: string[] | undefined): ParsedParams => {
  let siteName: string | undefined;
  let categoryName: string | undefined;
  let language: string | undefined;
  let redirectNotFound = false;

  siteName = params?.[0];

  if (params && params.length > 1) {
    const lastParam = params[params.length - 1];

    if (isLanguageCode(lastParam)) {
      language = lastParam;
      // If there are more than 2 parameters, the second last parameter is the category
      if (params.length > 2) {
        categoryName = params[params.length - 2];
      }
    } else {
      // If the last param is not a language, then it's a category
      categoryName = lastParam;
      // If there are 3 parameters and the last one is not a language, there's no language specified
      if (params.length === 3) {
        language = undefined;
        redirectNotFound = true;
      }
    }
  }

  return { siteName, categoryName, language, redirectNotFound };
};

export const processCategoryAndProducts = (
  menu: Menu,
  category: Category,
  language: string,
  isDefaultLanguage: boolean
) => {
  // Set the category title based on the language
  menu.categoryTitle =
    isDefaultLanguage || !category.titles?.[language]
      ? category.title
      : category.titles[language];
  menu.categoryPageName = category.pageName;

  const productsMap = new Map(
    menu.products.map((product: Partial<Product>) => [product.itemId, product])
  );

  // Filtering products that are available and either in the productsOrder or belong to the category
  const filteredProducts = Array.from(productsMap.values()).filter(
    (product) =>
      product.itemId &&
      product.available && // Checks if product is available
      (category.productsOrder?.includes(product.itemId) || // Product is in the productsOrder
        product.category?.includes(category.categoryId)) // Product belongs to the category
  );

  // Sorting the products based on productsOrder
  menu.products = filteredProducts.sort((a: any, b: any) => {
    const indexA = category.productsOrder?.indexOf(a.itemId) ?? Infinity;
    const indexB = category.productsOrder?.indexOf(b.itemId) ?? Infinity;
    return indexA - indexB;
  });

  // Update and clean product details based on the language
  menu.products = menu.products
    .filter((p) => p)
    .map((product: Partial<Product>) => {
      // Set title and description based on the language
      const title =
        isDefaultLanguage || !product.titles?.[language]
          ? product.title
          : product.titles[language];
      const description =
        isDefaultLanguage || !product.descriptions?.[language]
          ? product.description
          : product.descriptions[language];

      // Integrate the additional logic here
      const updatedProduct = processProduct(product, menu.currency);

      return {
        ...updatedProduct,
        title: title || "",
        description: description || "",
      };
    });
};

const processProduct = (
  product: Partial<Product>,
  currency: string
): Partial<Product> => {
  const price =
    product.previewList && product.previewList.length > 0
      ? product.previewList[0].price ?? product.onlinePrice ?? 0
      : product.onlinePrice ?? 0;

  const variantTypes = product.variants?.length
    ? product.variants.map((variant: any) => ({
        ...variant,
        options: variant.options.map((option: any, index: number) => ({
          selected: index === 0,
          name: option,
          number: index,
        })),
      }))
    : [];

  const variants = product.previewList
    ? product.previewList.map((variant: any, index: number) => ({
        selected: index === 0,
        name: variant.name,
        number: index,
        price: variant.price ?? product.onlinePrice ?? product.price ?? 0,
      }))
    : [];

  const additions =
    product.additions && product.additions.length > 0
      ? product.additions
          .filter(
            (addition: any) => addition.name && addition.price !== undefined
          )
          .map((addition: any, index: number) => ({
            selected: null,
            name: addition.name,
            price: addition.price,
            fPriceString: formatPrice(addition.price || 0, currency),
            number: index,
          }))
      : [];

  return {
    ...product,
    currency: currency,
    onlinePrice: price,
    formattedPrice: getFormattedPrice(product, currency) || "",
    quantity: 1,
    variantTypes,
    variants,
    additions,
  };
};

const getFormattedPrice = (item: Partial<Product>, currency: string) => {
  if (item.onlineStore || item.hasAdditions || item.hasVariants) {
    let price = (
      typeof item.onlineStore === "object"
        ? item.onlineStore?.price
        : item.onlinePrice
    ) as any;
    price = Number(price || 0).toFixed(2);
    const { minPrice, maxPrice } = getMinMaxPrice(item);
    const showMinMaxPrice = minPrice && maxPrice && minPrice !== maxPrice;
    if (showMinMaxPrice) {
      return currency === "$"
        ? `${currency}${minPrice.toFixed(2)} - ${currency}${maxPrice.toFixed(
            2
          )}`
        : `${minPrice.toFixed(2)}${currency} - ${maxPrice.toFixed(
            2
          )}${currency}`;
    } else {
      if (currency === "$") {
        return currency + price;
      }
      return price + currency;
    }
  }
  return item.price?.toString();
};

const getMinMaxPrice = (
  item: Partial<Product>
): { minPrice: number | undefined; maxPrice: number | undefined } => {
  let minPrice;
  let maxPrice;
  if (item.previewList && item.previewList.length) {
    for (let i = 0; i < item.previewList.length; i++) {
      const vPrice = item.previewList[i].price;
      if (minPrice === undefined || minPrice > vPrice) minPrice = vPrice;
      if (maxPrice === undefined || maxPrice < vPrice) maxPrice = vPrice;
    }
  }
  return { minPrice, maxPrice };
};

const formatPrice = (price: number, currency: string) => {
  return currency === "$"
    ? `${currency}${price.toFixed(2)}`
    : `${price.toFixed(2)}${currency}`;
};

// Define types
type DayNumber = "1" | "2" | "3" | "4" | "5" | "6" | "0";

const dayNumberEq: { [key: string]: DayNumber } = {
  Monday: "1",
  Tuesday: "2",
  Wednesday: "3",
  Thursday: "4",
  Friday: "5",
  Saturday: "6",
  Sunday: "0",
};
const dayTypeEq: { [key: string]: DayNumber[] } = {
  weekdays: ["1", "2", "3", "4", "5"],
  all: ["0", "1", "2", "3", "4", "5", "6"],
  weekends: ["0", "6"],
  "1": ["1"],
  "2": ["2"],
  "3": ["3"],
  "4": ["4"],
  "5": ["5"],
  "6": ["6"],
  "0": ["0"],
};

export const processCategories = (
  categories: Category[],
  categoriesOrder: string[],
  defaultTimezone: string,
  language?: string,
  isDefaultLanguage?: boolean
): Partial<Category>[] => {
  if (categoriesOrder) {
    categories = categoriesOrder
      .map((categoryId) =>
        categories.find((category) => category.categoryId === categoryId)
      )
      .filter((cat): cat is Category => cat !== undefined);
  }

  // Get the current day and time
  const timezone = defaultTimezone || "UTC";
  const now = moment().tz(timezone);
  const day = now.format("dddd"); // 'dddd' for full day name
  const hourString = now.format("HHmm"); // 'HHmm' for hour and minute in 24-hour format
  const dayNumber: DayNumber = dayNumberEq[day];

  // Process and filter categories
  return (categories || []).reduce(
    (processedCategories: Partial<Category>[], category) => {
      let shouldInclude = category.available;

      if (!isDefaultLanguage && language) {
        category.title =
          category.titles && category.titles[language]
            ? category.titles[language]
            : category.title;
      }

      const schedules = category.schedule;
      if (schedules && schedules.length) {
        shouldInclude = schedules.some((schedule: Schedule) => {
          const start = schedule.timeStart.replace(/[^0-9]/g, ""); // Removes non-numeric characters
          const end = schedule.timeEnd.replace(/[^0-9]/g, ""); // Removes non-numeric characters
          return (
            dayTypeEq[schedule.type].includes(dayNumber) &&
            hourString >= start &&
            hourString <= end
          );
        });
      }

      if (shouldInclude) {
        const { schedule, titles, productsOrder, available, checked, ...rest } =
          category;
        processedCategories.push(rest);
      }

      return processedCategories;
    },
    []
  );
};
